:root {
  --color-primary: #00eaeb;
  --color-secondary: #ff7938;
  --color-green: rgb(28, 109, 32);
  --color-light-green: rgb(192, 240, 194);
  --color-red: rgb(170, 36, 36);
  --color-light-grey: #f1f1f1;
  --color-black: #000;
  --color-grey: #ccc;
  --color-gold: #ffd700;
  --color-silver: #ddd;
  --color-bronze: #cd7f32;
  --color-border: #ddd;
  --mobile-view: #ddd;

  --font-family-body: "Rubik", sans-serif;
  --font-weight-body: 400;
  --font-weight-bold: 600;
}
